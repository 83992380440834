<template>
  <div class="card w-3/5 mx-auto">
    <h2 class="card-header">
      Home Page
    </h2>

    <div class="card-body">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium aliquam posuere.
        Vestibulum pharetra nisl vel risus facilisis sollicitudin. Nulla id velit at turpis
        scelerisque fringilla. Suspendisse ut maximus urna, vitae egestas elit. Quisque quis
        metus. Nulla efficitur eleifend diam, et euismod libero fringilla ac. Maecenas blandit,
        a tristique venenatis, dui ipsum tincidunt leo, vel vehicula ipsum dui id nisl. Cras
        tincidunt et erat eu molestie. Nunc sodales tellus aliquam diam fermentum lobortis.
        sagittis, enim sed congue vulputate, ante odio porttitor turpis, fermentum euismod erat ex
        neque. Etiam sodales eu velit in imperdiet. Duis consectetur nisl dictum sollicitudin
        finibus. Aliquam tellus lectus, mollis id mollis ac, iaculis ac sapien. Donec nec velit id
        sem aliquam dignissim. Proin et facilisis libero.
      </p>

      <p>
        Vivamus odio diam, tincidunt sit amet lacus vitae, cursus lobortis erat. Ut tristique erat
        nec elit faucibus bibendum. Nulla facilisi. Aenean maximus est eleifend, blandit nunc nec,
        egestas neque. Integer vitae elit velit. Pellentesque placerat leo at eleifend vestibulum.
        Sed molestie vulputate neque, vel pulvinar lectus dapibus eu. Ut rutrum, ligula sit amet
        interdum lobortis, turpis lorem pellentesque turpis, ac iaculis lacus nisl non massa.
        Praesent vulputate, metus in molestie suscipit, nibh mi feugiat sapien, eu elementum arcu
        tellus ac dui. Pellentesque bibendum felis magna, vitae vestibulum nisl hendrerit in.
        at libero augue. Duis sem turpis, porta ut purus a, hendrerit viverra turpis.
      </p>

      <p>
        Donec luctus at elit sit amet consectetur. Duis et urna non sapien venenatis interdum in sit
        amet augue. Nam ut quam a lacus facilisis faucibus sed a nisl. Nulla facilisi. Vestibulum
        fermentum, mi id interdum accumsan, dui augue efficitur lorem, non aliquet erat lorem
        pulvinar magna. Vestibulum lobortis ac ante vitae eleifend. Maecenas vitae congue quam.
        Vivamus vel nulla dictum, aliquet diam in, dictum risus. Vivamus efficitur sagittis.
      </p>

      <p>
        Quisque ut lectus nisi. Praesent id odio nec felis volutpat lobortis. Class aptent taciti
        sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas tempor in
        diam in dictum. Cras in dolor vel quam facilisis pellentesque. Nulla non enim dolor. Vivamus
        consequat quam sed fringilla maximus. Proin turpis enim, eleifend pellentesque lacus in,
        venenatis gravida ipsum. Ut scelerisque eros ac interdum tincidunt. In hendrerit convallis
        tellus, at auctor magna. Aenean condimentum odio eu magna porttitor, nec ultrices orci
        dapibus. Integer mattis est neque, ac accumsan odio varius a. Vivamus tincidunt massa eget
        vestibulum vestibulum. Duis vehicula urna ipsum, ac ornare lectus dignissim non.
      </p>

      <p>
        Fusce leo nulla, eleifend sed molestie ut, iaculis eleifend est. Sed pellentesque leo sed
        risus mollis bibendum. Aenean dapibus scelerisque mi, dapibus convallis nunc pellentesque.
        Etiam a nulla turpis. Ut aliquet varius neque scelerisque lobortis. Morbi facilisis et nibh
        rhoncus. Suspendisse sit amet lacus ac sapien tempor finibus. Nam a ullamcorper nibh, a
        iaculis dui. Nullam commodo elit urna, vitae vestibulum neque tincidunt vel. Aliquam
        convallis aliquet ornare.
      </p>

      <p>
        Integer volutpat nisl eget ullamcorper blandit. In at odio a tellus condimentum efficitur.
        Aliquam ut diam ex. Praesent rhoncus nisi vel metus hendrerit, ac ullamcorper velit congue.
        Pellentesque rutrum accumsan purus, et cursus eros sagittis ut. Sed bibendum mollis
        convallis. Vestibulum non tincidunt turpis, non posuere tortor.
      </p>

      <p>
        Donec tristique tincidunt nisi, ut porta dolor vehicula ut. Donec ac dictum dolor, sed
        convallis felis. Suspendisse potenti. Mauris congue risus in urna dictum efficitur. Cras
        libero, scelerisque quis pharetra nec, pellentesque nec arcu. Sed sit amet sapien vitae dui
        luctus lobortis at quis mi. Maecenas ultricies, eros et tempus pulvinar, ligula dui
        erat, sit amet porta mauris ligula quis nisi. Phasellus lacinia sit amet diam sit amet
        elementum.
      </p>

      <p>
        Sed blandit vel est at malesuada. Fusce ac augue et nulla ullamcorper aliquam. Nunc gravida
        quis purus eget ullamcorper. Nam vel odio turpis. Proin sodales feugiat sagittis. Sed id
        accumsan lorem, nec faucibus magna. Sed faucibus eget eros rutrum varius. Donec libero enim,
        placerat ac odio sed, feugiat volutpat leo. Sed cursus aliquet venenatis. Sed sollicitudin
        quam ac luctus dictum. Suspendisse lacinia diam orci, nec placerat nisl volutpat ac. Duis
        dictum malesuada lacinia. Praesent laoreet est eu est porttitor vulputate. Quisque ultrices
        nulla eu finibus cursus.
      </p>

      <p>
        Nullam eget nibh in dui aliquet fringilla. Nullam pharetra, enim vel imperdiet iaculis, nisl
        lectus tincidunt arcu, vestibulum congue nibh nulla a nibh. Mauris malesuada sed risus
        imperdiet congue. Proin vitae nibh a lectus tristique commodo. Vestibulum vel nulla lacus.
        Aliquam sit amet mi quis nunc dignissim mattis at luctus odio. Proin pulvinar id arcu non
        commodo. Etiam viverra tellus at lectus luctus, sit amet blandit est commodo. Maecenas augue
        metus, lobortis placerat urna sed, congue gravida magna.
      </p>

      <p>
        Quisque eu felis mi. Vestibulum pharetra scelerisque placerat. Vestibulum varius rutrum
        mauris, eget commodo augue congue eu. Proin consectetur fringilla dapibus. Mauris eu nisi
        ultrices, blandit felis non, fringilla sapien. Suspendisse sit amet tortor ut eros vulputate
        accumsan sed vitae elit. Phasellus luctus, felis et pulvinar vestibulum, orci nunc varius
        ante, sed volutpat ante urna tempus libero. Praesent auctor nibh semper, aliquet magna
        pellentesque, ullamcorper felis.
      </p>

      <p>
        Suspendisse potenti. Pellentesque habitant morbi tristique senectus et netus et malesuada
        fames ac turpis egestas. In cursus turpis ante, a sagittis eros condimentum id. Aliquam non
        ligula vel felis hendrerit finibus. Aliquam auctor nisi nunc, ut tincidunt justo euismod.
        Suspendisse lacinia sed eros sed auctor. Cras vitae arcu mauris. Donec et ante sit amet erat
        sodales porttitor. Quisque imperdiet tortor nec erat consectetur consequat. Fusce et
        sollicitudin ante, bibendum varius neque. Nulla tempus quis enim in sodales. Donec eget nisi
        a nisl hendrerit viverra. Cras tempor euismod erat id posuere. Mauris ante ex, porta ut
        facilisis vitae, efficitur a dolor. Integer velit diam, porttitor sed ante a, commodo
        ullamcorper massa.
      </p>

      <p>
        Vivamus blandit ornare ipsum, sit amet ullamcorper purus vestibulum ut. Maecenas quis ipsum
        orci. Phasellus tempor tristique leo sit amet congue. Donec ut ante lacus. Sed est lorem,
        blandit et urna at, molestie iaculis arcu. Duis ut dignissim turpis. Donec faucibus luctus
        sapien eget pulvinar. Mauris feugiat condimentum purus, ac auctor orci tincidunt et. Duis
        pretium vel erat et ornare. Suspendisse posuere turpis nec elementum feugiat.
      </p>

      <p>
        Donec a feugiat enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc faucibus
        est non elementum euismod. Fusce blandit imperdiet fermentum. Cras suscipit purus purus, sed
        semper lectus varius ut. Nam et leo sodales, pharetra lectus et, cursus arcu. Aenean eget
        magna vel dolor condimentum vehicula a porta mi. Mauris ultrices porta elit quis viverra.
        Nam at finibus metus. Quisque varius sit amet nisi ut egestas. Donec elementum lacus nec
        massa viverra rutrum. Aliquam elementum sem sed lectus semper fermentum. Pellentesque
        habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed
        sollicitudin odio molestie dui iaculis, non fermentum orci aliquet. Quisque hendrerit cursus
        facilisis.
      </p>

      <p>
        Vivamus mollis congue laoreet. Proin auctor felis in nisi faucibus tincidunt. Phasellus
        ultrices sem purus, sed congue nunc semper in. Cras egestas dignissim turpis a sollicitudin.
        Aenean et erat in arcu rhoncus pretium. Ut eu diam et nunc volutpat laoreet sed quis magna.
        Vestibulum ut tincidunt dolor, eu finibus nibh.
      </p>

      <p>
        Donec fermentum euismod ante eget finibus. Mauris gravida arcu ut ullamcorper euismod.
        Pellentesque dictum sit amet lacus ut semper. Quisque dictum sagittis magna a maximus.
        Quisque mauris est, luctus id sapien sit amet, luctus posuere nisi. Phasellus feugiat porta
        ligula, at ornare ligula ultricies a. Suspendisse facilisis dolor eget dolor fermentum
        molestie imperdiet id dolor.
      </p>

      <p>
        Nulla ex sapien, suscipit nec augue vel, pretium convallis lacus. In dictum turpis ligula.
        Integer sapien odio, accumsan a urna vel, luctus euismod nisl. Mauris in turpis nec nulla
        lobortis luctus. Cras lacinia faucibus porttitor. Vivamus at porttitor velit, consequat
        aliquam justo. Morbi viverra at augue id tincidunt. Proin at diam metus. Duis eget pretium
        dolor. Morbi in sagittis ligula.
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomePage',
};
</script>
