<template>
  <div class="card w-3/5 mx-auto">
    <h2 class="card-header">
      This is an about page!
    </h2>
    <div class="card-body">
      "About" stuff goes here.
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>
